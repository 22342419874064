<template>
  <div>
    <div class="top-img" :class="{ 'image-loaded': imageLoaded }">
      <img loading="lazy" :src="wineImage" class="top-bg" @load="onImageLoad"/>
    </div>
    <div class="categories">
      <div class="container">
        <div class="col-12 mb-5">
          <span class="section-title">{{ $t("vueRoutes.entriesTitle") }}</span>
        </div>
        <div class="filter-section row align-items-end">
          <div class="col-12 col-md-2 d-flex flex-column">
            <label for="typeSelect">Selecione um tipo:</label>
            <select class="form-control" id="typeSelect" v-model="activeType">
              <option value="wine">Uvas</option>
              <option value="oliveOil">Azeitona</option>
            </select>
          </div>
          <div class="col-12 col-md-2">
            <input class="form-control"
              required
              type="text"
              v-model="nif"
              placeholder="Associado NIF Nº"
            />
          </div>
          <div class="col-12 col-md-2">
            <input class="form-control"
              required
              type="password"
              v-model="password"
              placeholder="Password"
            />
          </div>
          <div class="col-12 col-md-2" v-if="years.length">
            <select class="form-control" id="yearSelect" v-model="selectedYear" aria-placeholder="Ano">
              <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
            </select>
          </div>
          <div class="col-12 col-md-2">
            <button class="btn-submit" @click="fetchData">Submeter</button>
          </div>
        </div>
        <div class="table-section">
          <table>
            <thead>
              <tr>
                <th>Associado NIF</th>
                <th>Associado Nome</th>
                <th v-if="activeType === 'wine'">Tipo Uva</th>
                <th v-if="activeType === 'oliveOil'">Tipo Azeitona</th>
                <th v-if="activeType === 'wine'">Peso Líquido</th>
                <th v-if="activeType === 'oliveOil'">Kg Azeitona</th>
                <th>Data</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="filteredData.length === 0 && !nif && !password">
                <td colspan="8" class="text-center">Inserir parâmetros de pesquisa</td>
              </tr>
              <tr v-if="filteredData.length === 0 && nif && password">
                <td colspan="8" class="text-center">Nenhum resultado encontrado</td>
              </tr>
              <tr v-for="entry in filteredData" :key="entry.id">
                <td>{{ entry.associado_nif }}</td>
                <td>{{ entry.associado_nome }}</td>
                <td v-if="activeType === 'wine'">{{ entry.tipo_uva }}</td>
                <td v-if="activeType === 'oliveOil'">{{ entry.tipo_azeitona }}</td>
                <td>{{ activeType === 'wine' ? entry.peso_liquido : entry.kg_azeitona_entregue }}</td>
                <td>{{ entry.data_criacao }}</td>
                <td><button class="btn-details" @click="openPopup(entry)">Ver Detalhes</button></td>
              </tr>
              <tr v-if="filteredData.length > 0">
                <td colspan="4" class="text-right"><strong>Total Kg:</strong></td>
                <td v-if="activeType === 'wine'">
                  <b>Branca:</b> {{ totalPesoLiquidoBranca }} Kg
                  <br>
                  <b>Tinta:</b> {{ totalPesoLiquidoTinta }} Kg
                  <br>
                  <b>Total:</b> {{ totalPesoLiquido }} Kg
                </td>
                <td v-if="activeType === 'oliveOil'">{{ totalKgAzeitona }} Kg</td>
                <td colspan="2"></td>
              </tr>
              <tr v-if="filteredData.length > 0 && activeType === 'wine'">
                <td colspan="4" class="text-right"><strong>Total Grau(s)/Kg:</strong></td>
                <td>
                  <b>Branca:</b> {{ totalGrauKgBranca }} Grau(s)/Kg
                  <br>
                  <b>Tinta:</b> {{ totalGrauKgTinta }} Grau(s)/Kg
                  <!-- <br>
                  <b>Total:</b>{{ totalGrauKg }} Grau(s)/Kg -->
                </td>
                <td colspan="3"></td>
              </tr>
              <tr v-if="filteredData.length > 0">
                <td colspan="4" class="text-right"><strong>Total Litros:</strong></td>
                <td v-if="activeType === 'wine'">
                  <b>Branca:</b> {{ totalLitrosBranca }} Litros
                  <br>
                  <b>Tinta:</b> {{ totalLitrosTinta }} Litros
                  <br>
                  <b>Total:</b>{{ totalLitros }} Litros
                </td>
                <td v-if="activeType === 'oliveOil'">
                  {{ totalLitros }} Litros
                </td>
                <td colspan="3"></td>
              </tr>
              <tr v-if="filteredData.length > 0 && activeType === 'wine'">
                <td colspan="4" class="text-right"><strong>Média Grau:</strong></td>
                <td>
                  <b>Branca:</b> {{ averageGrauBranca }}
                  <br>
                  <b>Tinta:</b> {{ averageGrauTinta }}
                  <br>
                  <b>Geral:</b>{{ averageGrau }}
                </td>
                <td colspan="3"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-if="showPopup" class="popup">
      <div class="popup-content">
        <span class="close" @click="closePopup">&times;</span>
        <h3 class="title-secondary">Detalhes do Associado</h3>
        <p><strong>ID:</strong> {{ selectedEntry.id }}</p>
        <p><strong>Associado ID:</strong> {{ selectedEntry.associado_id }}</p>
        <p><strong>Associado NIF:</strong> {{ selectedEntry.associado_nif }}</p>
        <p><strong>Associado Nome:</strong> {{ selectedEntry.associado_nome }}</p>
        <p><strong>Endereço 1:</strong> {{ selectedEntry.associado_endereco_1 }}</p>
        <p v-if="selectedEntry.associado_endereco_2"><strong>Endereço 2:</strong> {{ selectedEntry.associado_endereco_2 }}</p>
        <p><strong>Código Postal:</strong> {{ selectedEntry.associado_codigo_postal }}</p>
        <p><strong>Propriedade:</strong> {{ selectedEntry.propriedade }}</p>
        <p v-if="activeType === 'wine'"><strong>Tipo Uva:</strong> {{ selectedEntry.tipo_uva }}</p>
        <p v-if="activeType === 'wine'"><strong>Grau:</strong> {{ selectedEntry.grau }}</p>
        <p v-if="activeType === 'wine'"><strong>Tina:</strong> {{ selectedEntry.tina }}</p>
        <p v-if="activeType === 'wine'"><strong>Balança:</strong> {{ selectedEntry.balanca }}</p>
        <p v-if="activeType === 'wine'"><strong>Número Entrada:</strong> {{ selectedEntry.numero_entrada }}</p>
        <p v-if="activeType === 'wine'"><strong>Total Bruto:</strong> {{ selectedEntry.total_bruto }}</p>
        <p v-if="activeType === 'wine'"><strong>Total Tara:</strong> {{ selectedEntry.total_tara }}</p>
        <p v-if="activeType === 'wine'"><strong>Peso Líquido:</strong> {{ selectedEntry.peso_liquido }}</p>
        <p v-if="activeType === 'wine'"><strong>Litros:</strong> {{ selectedEntry.litros }}</p>
        <p v-if="activeType === 'wine'"><strong>Graus/Kg:</strong> {{ selectedEntry.graus_kg }}</p>
        <p v-if="activeType === 'wine'"><strong>Anfora:</strong> {{ selectedEntry.anfora }}</p>
        <p v-if="activeType === 'oliveOil'"><strong>Tipo Azeitona:</strong> {{ selectedEntry.tipo_azeitona }}</p>
        <p v-if="activeType === 'oliveOil'"><strong>Kg Azeitona Entregue:</strong> {{ selectedEntry.kg_azeitona_entregue }}</p>
        <p v-if="activeType === 'oliveOil'"><strong>Acondicionamento:</strong> {{ selectedEntry.acondicionamento }}</p>
        <p v-if="activeType === 'oliveOil'"><strong>Tipo Azeite:</strong> {{ selectedEntry.tipo_azeite }}</p>
        <p v-if="activeType === 'oliveOil'"><strong>Rendimento:</strong> {{ selectedEntry.rendimento }}</p>
        <p v-if="activeType === 'oliveOil' && selectedEntry.rendimento_final"><strong>Rendimento Final:</strong> {{ selectedEntry.rendimento_final }}</p>
        <p v-if="activeType === 'oliveOil'"><strong>Kg Azeite Obtidos:</strong> {{ selectedEntry.kg_azeite_obtidos }}</p>
        <p v-if="activeType === 'oliveOil'"><strong>Acidez:</strong> {{ selectedEntry.acidez }}</p>
        <p v-if="activeType === 'oliveOil'"><strong>Maquia:</strong> {{ selectedEntry.maquia }}</p>
        <p v-if="activeType === 'oliveOil'"><strong>Total Azeite Levantamento:</strong> {{ selectedEntry.total_azeite_levantamento }}</p>
        <p><strong>Data Criação:</strong> {{ selectedEntry.data_criacao }}</p>
        <p><strong>Data Atualização:</strong> {{ selectedEntry.data_atualizacao }}</p>
        <p v-if="selectedEntry.observacoes"><strong>Observações:</strong> {{ selectedEntry.observacoes }}</p>
      </div>
    </div>
  </div>
</template>
  
<script>
import { mapState, mapActions } from "vuex";
import InputGetList from "@/classes/InputGetList";
import { API_HELPER } from "@/helpers/api.js";
import axios from "axios";
import debounce from 'lodash/debounce';

export default {
  metaInfo() {
    return {
      title: this.$t("vueRoutes.entriesTitle"),
      titleTemplate: "%s - Cooperativa de Freixo de Numão",
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        { name: "description", content: this.$t("vueRoutes.wineDescription") },
        { name: this.$t("vueRoutes.defaultKeywords") },
      ],
      link: [
        {
          rel: "alternate",
          hreflang: "pt",
          href: API_HELPER.pathJoin([
            process.env.VUE_APP_URL_ROOT,
            this.$t("vueRoutes.entries", "pt"),
          ]),
        },
        {
          rel: "alternate",
          hreflang: "en",
          href: API_HELPER.pathJoin([
            process.env.VUE_APP_URL_ROOT,
            this.$t("vueRoutes.entries", "en"),
          ]),
        },
      ],
    };
  },
  data() {
    return {
      activeType: "wine",
      nif: "",
      password: "",
      originalData: [],
      filteredData: [],
      imageLoaded: false,
      showPopup: false,
      selectedEntry: null,
      years: [],
      selectedYear: null,
    };
  },
  mounted() {
    this.initializeHero();
  },
  computed: {
    ...mapState({
      herosOutput: (state) => state.heros.listOutput,
    }),
    wineImage() {
      let result = null;

      if (
        this.herosOutput &&
        this.herosOutput.data &&
        this.herosOutput.data.heroItems &&
        this.herosOutput.data.heroItems.length
      ) {
        const item = this.herosOutput.data.heroItems.find(
          (obj) =>
            obj.types && obj.types.some((typeObj) => typeObj.type === "Wines")
        );
        result = item && item.image
          ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, item.image])
          : null;
      }

      return result;
    },
    totalPesoLiquido() {
      return this.filteredData.reduce((sum, entry) => sum + parseFloat(entry.peso_liquido || 0), 0).toFixed(2);
    },
    totalKgAzeitona() {
      return this.filteredData.reduce((sum, entry) => sum + parseFloat(entry.kg_azeitona_entregue || 0), 0).toFixed(2);
    },
    totalGrauKg() {
      const grauPerKg = this.filteredData.reduce((sum, entry) => sum + parseFloat(entry.graus_kg || 0), 0).toFixed(2);
      return this.filteredData.length ? (grauPerKg / this.filteredData.length).toFixed(2) : 0;
    },
    totalLitros() {
      return this.filteredData.reduce((sum, entry) => sum + parseFloat(entry.litros || entry.total_azeite_levantamento || 0), 0).toFixed(2);
    },
    averageGrau() {
      const totalGrau = this.filteredData.reduce((sum, entry) => sum + parseFloat(entry.grau || entry.rendimento || 0), 0);
      return this.filteredData.length ? (totalGrau / this.filteredData.length).toFixed(2) : 0;
    },
    totalPesoLiquidoBranca() {
      return this.filteredData
        .filter(entry => entry.tipo_uva === 'Branca')
        .reduce((sum, entry) => sum + parseFloat(entry.peso_liquido || 0), 0)
        .toFixed(2);
    },
    totalPesoLiquidoTinta() {
      return this.filteredData
        .filter(entry => entry.tipo_uva === 'Tinta')
        .reduce((sum, entry) => sum + parseFloat(entry.peso_liquido || 0), 0)
        .toFixed(2);
    },
    totalLitrosBranca() {
      return this.filteredData
        .filter(entry => entry.tipo_uva === 'Branca')
        .reduce((sum, entry) => sum + parseFloat(entry.litros || 0), 0)
        .toFixed(2);
    },
    totalLitrosTinta() {
      return this.filteredData
        .filter(entry => entry.tipo_uva === 'Tinta')
        .reduce((sum, entry) => sum + parseFloat(entry.litros || 0), 0)
        .toFixed(2);
    },
    averageGrauBranca() {
      const totalGrau = this.filteredData
        .filter(entry => entry.tipo_uva === 'Branca')
        .reduce((sum, entry) => sum + parseFloat(entry.grau || 0), 0);
      const count = this.filteredData.filter(entry => entry.tipo_uva === 'Branca').length;
      return count ? (totalGrau / count).toFixed(2) : 0;
    },
    averageGrauTinta() {
      const totalGrau = this.filteredData
        .filter(entry => entry.tipo_uva === 'Tinta')
        .reduce((sum, entry) => sum + parseFloat(entry.grau || 0), 0);
      const count = this.filteredData.filter(entry => entry.tipo_uva === 'Tinta').length;
      return count ? (totalGrau / count).toFixed(2) : 0;
    },
    totalGrauKgBranca() {
      const grauPerKg = this.filteredData
        .filter(entry => entry.tipo_uva === 'Branca')
        .reduce((sum, entry) => sum + parseFloat(entry.graus_kg || 0), 0)
        .toFixed(2);
      return this.filteredData.filter(entry => entry.tipo_uva === 'Branca').length ? (grauPerKg / this.filteredData.filter(entry => entry.tipo_uva === 'Branca').length).toFixed(2) : 0;
    },
    totalGrauKgTinta() {
      const grauPerKg = this.filteredData
        .filter(entry => entry.tipo_uva === 'Tinta')
        .reduce((sum, entry) => sum + parseFloat(entry.graus_kg || 0), 0)
        .toFixed(2);
      return this.filteredData.filter(entry => entry.tipo_uva === 'Tinta').length ? (grauPerKg / this.filteredData.filter(entry => entry.tipo_uva === 'Tinta').length).toFixed(2) : 0;
    }
  },
  methods: {
    ...mapActions({
      getHeros: "heros/getList",
    }),
    joinPaths(relativePath) {
      return API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, relativePath]);
    },
    initializeHero() {
      const itemsPerPage = 99;
      const currentPage = 1;
      const sorter = 1;
      const propertyName = "id";
      const searchText = "";

      var input = new InputGetList(
        itemsPerPage,
        currentPage,
        sorter,
        propertyName,
        searchText
      );

      input.visible = true;
      this.getHeros(input);
    },
    async fetchData() {
      try {
        const headers = {
          'api-key': process.env.VUE_APP_PASTADIGITAL_KEY
        };

        if (!this.nif || !this.password) {
          this.filteredData = [];
          return;
        }
        const params = new URLSearchParams({
          nif: this.nif,
          password: this.password
        }).toString();

        let apiUrl = '';

        if (this.activeType === "wine") {
          apiUrl = `${process.env.VUE_APP_WINE_ENTRIES}?${params}`;
        } else if (this.activeType === "oliveOil") {
          apiUrl = `${process.env.VUE_APP_OLIVEOIL_ENTRIES}?${params}`;
        }

        if (apiUrl) {
          const response = await axios.get(apiUrl, { headers });
          this.originalData = response.data;
          this.filteredData = this.originalData;

          // Extract years from data_criacao and store them in the years array
          const years = this.filteredData.map(entry => new Date(entry.data_criacao).getFullYear());
          this.years = [...new Set(years)]; // Get unique years

          // Set selectedYear to the most recent year and filter data
          if (this.years.length > 0) {
            this.selectedYear = Math.max(...this.years);
            this.filterByYear();
          }
        }
      } catch (error) {
        alert("Por favor, insira um NIF e Password válidos", error);
        this.filteredData = [];
      }
    },
    filterByYear() {
      if (this.selectedYear) {
        this.filteredData = this.originalData.filter(entry => new Date(entry.data_criacao).getFullYear() === this.selectedYear);
      } else {
        this.filteredData = this.originalData; // Reset to original data if no year is selected
      }
    },
    onImageLoad() {
      this.imageLoaded = true;
    },
    openPopup(entry) {
      this.selectedEntry = entry;
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
      this.selectedEntry = null;
    },
  },
  created() {
    // Create a debounced version of fetchData
    this.debouncedFetchData = debounce(this.fetchData, 500);
  },
  watch: {
    selectedYear: 'filterByYear',
    nif: {
      handler: "debouncedFetchData",
    },
    password: {
      handler: "debouncedFetchData",
    },
    activeType(newType, oldType) {
      if (newType !== oldType) {
        // Clear table data when type changes
        this.filteredData = [];

        // Trigger fetchData if NIF and password are filled
        if (this.nif && this.password) {
          this.fetchData();
        }
      }
    },
  }
};
</script>
  
<style scoped>
.top-img {
  position: relative;
  width: 100%;
  height: 465px;
}

.top-img .top-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.top-img::before {
  content: "";
  width: 100%;
  height: 80%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, #000, rgba(84, 84, 84, 0));
  mix-blend-mode: multiply;
  opacity: 0;
  z-index: 4;
  transition: opacity 0.5s ease;
}

.top-img.image-loaded::before {
  opacity: 0.45;
}

.section-title {
  font-size: 40px;
  text-transform: uppercase;
  color: var(--primary-color);
}
.title-secondary{
  font-size: 30px;
  text-transform: uppercase;
  color: var(--secondary-color);
}
.sub-title-secondary{
  font-size: 20px;
  text-transform: uppercase;
  color: var(--secondary-color);
}

.categories {
  margin-top: 3rem;
  margin-bottom: 3rem;
  overflow: auto;
}

.filter-section {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin-bottom: 20px;
}
.filter-section label {
  margin-right: 10px;
}
.filter-section input,
.filter-section select {
  margin-right: 15px;
  padding: 5px;
}
.table-section {
  margin-top: 20px;
}
table {
  width: 100%;
  border-collapse: collapse;
}
thead {
  background-color: #f0f0f0;
}
th{
  color: var(--secondary-color);
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}
td {
  color: var(--primary-color);
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}
.text-center {
  text-align: center;
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.popup-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 600px;
  max-height: 80%;
  position: relative;
  overflow-y: auto;
}
.close {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
}
.btn-details {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.btn-submit {
  background-color: var(--primary-color);
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
@media screen and (max-width: 550px) {
  .filter-section {
    flex-direction: column;
  }
  .filter-section, select, input {
    width: 100% !important;
  }
  .popup-content {
    max-height: 80%;
  }
}
</style>